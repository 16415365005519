const HmInput = () => import('./HmInput.vue');
const HmCheckboxGroup = () => import('./HmCheckboxGroup.vue');
const HmCheckbox = () => import('./HmCheckbox.vue');
const HmRadioGroup = () => import('./HmRadioGroup.vue');
const HmSelect = () => import('./HmSelect.vue');
const HmSearch = () => import('./HmSearch.vue');
const HmTextArea = () => import('./HmTextArea.vue');
const HmCalendar = () => import('./HmCalendar.vue');
const HmGraphCalendar = () => import('./HmGraphCalendar.vue');
const HmUploader = () => import('./HmUploader.vue');
const HmCounter = () => import('./HmCounter.vue');
const HmContact = () => import('./HmContact.vue');
const HmNetwork = () => import('./HmNetwork.vue');
const HmGroup = () => import('./hm-group');
const HmTable = () => import('./HmTable.vue');
const HmFias = () => import('./HmFias.vue');
const HmEmployeeReplacement = () => import('./HmEmployeeReplacement');
const HmResource = () => import('./HmResource.vue');
const HmCmdb = () => import('./HmCmdb.vue');
const HmDatabase = () => import('./HmDatabase.vue');
const HmScale = () => import('./HmScale.vue');
const HmCalculatedField = () => import('./HmCalculatedField.vue');
const HmDocument = () => import('./HmDocument.vue');

export default {
  'hm-select': HmSelect,
  'hm-search': HmSearch,
  'hm-input': HmInput,
  'hm-checkbox-group': HmCheckboxGroup,
  'hm-checkbox': HmCheckbox,
  'hm-radio-group': HmRadioGroup,
  'hm-text-area': HmTextArea,
  'hm-graph-calendar': HmGraphCalendar,
  'hm-uploader': HmUploader,
  'hm-calendar': HmCalendar,
  'hm-counter': HmCounter,
  'hm-contact': HmContact,
  'hm-network': HmNetwork,
  'hm-group': HmGroup,
  'hm-table': HmTable,
  'hm-fias': HmFias,
  'hm-employee-replacement': HmEmployeeReplacement,
  'hm-resource': HmResource,
  'hm-cmdb': HmCmdb,
  'hm-database': HmDatabase,
  'hm-scale': HmScale,
  'hm-calculated-field': HmCalculatedField,
  'hm-document': HmDocument,
};
