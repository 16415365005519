export default {
  text: 'div',
  input: 'hm-input',
  checkboxGroup: 'hm-checkbox-group',
  checkbox: 'hm-checkbox',
  radioGroup: 'hm-radio-group',
  select: 'hm-select',
  textarea: 'hm-text-area',
  calendar: 'hm-calendar',
  graphCalendar: 'hm-graph-calendar',
  uploader: 'hm-uploader',
  search: 'hm-search',
  counter: 'hm-counter',
  contactInfo: 'hm-contact',
  priority: 'hm-select',
  network: 'hm-network',
  group: 'hm-group',
  inlineInputs: 'hm-group', // для SM тикетов
  table: 'hm-table',
  fiasSearch: 'hm-fias',
  employeeReplacement: 'hm-employee-replacement',
  resourceField: 'hm-resource',
  cmdb: 'hm-cmdb',
  database: 'hm-database',
  scale: 'hm-scale',
  calculatedField: 'hm-calculated-field',
  document: 'hm-document',
};
