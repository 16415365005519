const setDomProps = (el) => {
  const obj = {};
  switch (el.class) {
  case 'text':
    obj.innerHTML = el.value;
    break;
  default:
  }
  return obj;
};

export default setDomProps;
