<script>
import components from '@/components/hm-form/controls/';
import renderElement from './helpers/renderElement';

export default {
  name: 'HmFormFields',
  components,
  model: {
    prop: 'model',
    event: 'change',
  },
  provide() {
    return {
      fields: this.fields,
    };
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    model: {
      type: Object,
      default: () => ({}),
    },
    globalState: {
      type: Object,
      default: () => ({}),
    },
    groupLinks: {
      type: Array,
      default: undefined,
    },
    parentGroupId: {
      type: String,
      default: undefined,
    },
  },
  render(h) {
    return renderElement(this, h, {
      tag: 'div',
      children: this.fields,
    }, this.globalState, this.groupLinks, this.parentGroupId);
  },
};
</script>
