import componentsMap from './componentsMap';
import setProps from './setProps';
import setDomProps from './setDomProps';

const renderElement = (ctx, h, el, globalState, groupLinks, parentGroupId) => h(el.tag || componentsMap[el.class], {
  ...el.options,
  class: {
    'hm-form__wrapper': !el.class,
    'hm-form__element': !!el.class,
    'hm-form__element--hidden': el.visible === false && el.class !== 'group',
  },
  props: setProps(ctx, el, globalState, groupLinks, parentGroupId),
  domProps: setDomProps(el),
  key: el.id,
  on: {
    input(val) {
      if (el.id) {
        ctx.$emit('element-change', { id: el.id, value: val });
        ctx.$emit('change', { ...ctx.model, [el.id]: val });
      }
    },
  },
}, ((elements, state, links, groupId) => {
  if (typeof elements === 'string') return elements;
  if (elements && elements.length) {
    return elements.map((child) => renderElement(ctx, h, child, state, links, groupId));
  }
  return [];
})(el.children, globalState, groupLinks, parentGroupId));

export default renderElement;
