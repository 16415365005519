const setValidationRules = (el) => {
  const obj = {};
  if (el.required) {
    if (el.class === 'resourceField') {
      obj.resource = { isShowAccessType: !!el.isShowAccessType };
    }
    obj.required = true;
  }
  if (el.minLength) obj.min = el.minLength;
  if (el.maxLength) obj.max = el.maxLength;
  if (el.minValue) obj.min_value = el.minValue;
  if (el.maxValue) obj.max_value = el.maxValue;
  if (el.mask && el.mask.regex) {
    if (el.mask.regex.startsWith('function:')) {
      const validateFunction = el.mask.regex.slice(el.mask.regex.indexOf(':') + 1);

      obj[validateFunction] = true;
    } else {
      obj.regex = el.mask.regex;
    }

    if (el.mask.dateToShowType) {
      if (el.mask.dateToShowType === 1) {
        obj.dateBefore = el.showTime ? 1 : 0;
      }
      if (el.mask.dateToShowType === 2) {
        obj.dateAfter = el.showTime ? 1 : 0;
      }
    }
  }
  if (el.class === 'input' || el.class === 'textarea') {
    obj.safeString = true; // проверка на html-теги
  }
  if (el.class === 'calendar') {
    if (el.datesToShowType === 1) {
      obj.dateBeforeAndToday = {
        showTime: false,
      };
    }
    if (el.datesToShowType === 2) {
      obj.dateAfterAndToday = {
        showTime: false,
      };
    }
    if (el.datesToShowType === 5) {
      obj.dateBefore = true;
    }
    if (el.datesToShowType === 6) {
      obj.dateAfter = true;
    }
  }
  if (el.class === 'graphCalendar' && el.datesToShowType) {
    const controlSettings = {
      showTime: el.showTime,
      viewMode: el.viewMode,
    };
    if (el.datesToShowType === 1) {
      obj.dateBeforeAndToday = controlSettings;
    }
    if (el.datesToShowType === 2) {
      obj.dateAfterAndToday = controlSettings;
    }
  }
  if (el.class === 'network') {
    obj.network = true;
  }
  if (el.class === 'employeeReplacement') {
    obj.required = true;
    obj.replacement = { showEmployee: el.showEmployee };
  }
  // вообще это странно, но нужно для чат-бота
  if (el.class === 'checkbox') {
    obj.truely = true;
  }
  if (el.class === 'database') {
    obj.database = true;
  }
  return obj;
};

export default setValidationRules;
