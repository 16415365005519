import setValidationRules from './setValidationRules';

// TODO: объединить копипаст одинаковых свойств в каждом контроле

/**
 * Простановка нужных пропсов для каждого из типов контроллов
 * @param ctx {object} - компонент HmFormFields
 * @param el {object} - данные о контролле с бека
 * @param globalState {object} - модель формы, реактивна
 * @param groupLinks {object} - объект слежения за моделью для показа/скрытия групп
 * @param parentGroupId {string} - id родителя контрола
 * @returns {object} - объект пропсов для контролла
 */
const setProps = (ctx, el, globalState, groupLinks, parentGroupId) => {
  const obj = {};

  switch (el.class) {
  case 'text':
    break;
  case 'input':
    obj.required = !!el.required;
    obj.value = ctx.model[el.id];
    obj.rules = setValidationRules(el);
    obj.name = el.name;
    obj.hasMask = !!el.mask;
    obj.id = el.id;
    obj.placeholder = el.placeholder || el.name;
    obj.hintText = el.hintText || '';
    obj.options = {};
    if (el.minLength) obj.options.minLength = el.minLength;
    if (el.maxLength) obj.options.maxLength = el.maxLength;
    break;

  case 'counter':
    obj.required = !!el.required;
    obj.value = ctx.model[el.id];
    obj.rules = setValidationRules(el);
    obj.name = el.name;
    obj.id = el.id;
    obj.placeholder = el.placeholder || el.name;
    obj.hintText = el.hintText || '';
    obj.options = {};
    if (el.minValue) obj.options.min = el.minValue;
    if (el.maxValue) obj.options.max = el.maxValue;
    break;

  case 'contactInfo':
    obj.required = !!el.required;
    obj.rules = setValidationRules(el);
    obj.name = el.name;
    obj.id = el.id;
    obj.placeholder = el.placeholder || el.name;
    obj.hintText = el.hintText || '';
    obj.value = ctx.model[el.id];
    obj.default = el.defaultStringValue || '';
    break;

  case 'checkboxGroup':
    obj.required = !!el.required;
    obj.rules = setValidationRules(el);
    obj.name = el.name;
    obj.id = el.id;
    obj.placeholder = el.placeholder || el.name;
    obj.value = ctx.model[el.id];
    obj.items = el.listValues ? [...el.listValues.map((i) => ({
      value: i.id,
      label: i.name,
    }))] : [];
    break;

  case 'radioGroup':
    obj.required = !!el.required;
    obj.rules = setValidationRules(el);
    obj.name = el.name;
    obj.id = el.id;
    obj.placeholder = el.placeholder || el.name;
    obj.value = ctx.model[el.id];
    obj.items = el.listValues ? [...el.listValues.map((i) => ({
      value: i.id,
      label: i.name,
    }))] : [];
    break;

  case 'select':
    obj.required = !!el.required;
    obj.rules = setValidationRules(el);
    obj.name = el.name;
    obj.sendInHiddenGroup = el.sendInHiddenGroup;
    obj.id = el.id;
    // TODO: у селекта не всегда выставляется default value
    // пример форма tickets/create/sm1/ITS0000241, селект "оборотная сторона"
    obj.value = ctx.model[el.id];
    obj.placeholder = el.name;
    obj.items = el.listValues ? [...el.listValues.map((i) => ({
      id: i.id,
      label: i.name,
    }))] : [];
    break;

  case 'priority':
    obj.required = !!el.required;
    obj.rules = setValidationRules(el);
    obj.name = el.name;
    obj.id = el.id;
    obj.value = ctx.model[el.id];
    obj.placeholder = el.name;
    obj.items = el.listValues ? el.listValues
      .map((i) => ({
        id: i.id,
        label: i.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) : [];
    break;

  case 'cmdb':
    obj.required = !!el.required;
    obj.rules = setValidationRules(el);
    obj.name = el.name;
    obj.placeholder = el.name;
    obj.model = globalState;
    obj.value = ctx.model[el.id];
    obj.sendInHiddenGroup = el.sendInHiddenGroup;
    obj.cmdbId = el.id?.toString();
    obj.parentCmdbId = el.cmdbParentControlId?.toString();
    obj.cmdbClassName = el.cmdbClassName;
    obj.cmdbDefaultValueId = el.cmdbDefaultValueId;
    obj.isSearch = el.isSearch;
    obj.attributeNameAsItemName = el.attributeNameAsItemName;
    obj.items = Object.values(el.cmdbItemInfoMap)
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((ke) => ({
        id: String(ke.id),
        label: ke.text,
        info: ke.info || null,
        connectedGroups: ke.connectedGroups?.map((item) => String(item)) || null,
      }));
    obj.multipleValues = el.multipleValues;
    break;

  case 'search':
    obj.required = !!el.required;
    obj.rules = setValidationRules(el);
    obj.name = el.name;
    obj.id = el.id;
    obj.value = ctx.model[el.id];
    obj.placeholder = el.name;
    obj.multiple = el.multipleValues;
    obj.setLogin = el.specifyLogin;
    obj.items = el.listValues ? [...el.listValues.map((i) => ({
      id: i.id,
      label: i.name,
    }))] : [];
    obj.customEmployeeListId = el.customEmployeeListId;
    break;

  case 'textarea':
    obj.required = !!el.required;
    obj.value = ctx.model[el.id];
    obj.rules = setValidationRules(el);
    obj.name = el.name;
    obj.id = el.id;
    obj.placeholder = el.tooltipDescription || el.placeholder;
    break;

  case 'uploader':
    obj.required = !!el.required;
    obj.value = ctx.model[el.id];
    obj.rules = setValidationRules(el);
    obj.name = el.name;
    obj.placeholder = el.placeholder || el.name;
    obj.id = el.id;
    obj.hintText = el.isUseHint ? el.embeddedHint : '';
    break;

  case 'calendar':
    obj.required = !!el.required;
    obj.value = ctx.model[el.id];
    obj.rules = setValidationRules(el);
    obj.name = el.name;
    obj.id = el.id;
    obj.placeholder = el.placeholder || el.name;
    obj.datesToShowType = el.datesToShowType;
    obj.defaultDate = el.defaultValue?.name;
    obj.showDateByDefault = el.showDateByDefault;
    break;

  case 'graphCalendar':
    obj.required = !!el.required;
    obj.value = ctx.model[el.id];
    obj.rules = setValidationRules(el);
    obj.name = el.name;
    obj.id = el.id;
    obj.placeholder = el.placeholder || el.name;
    obj.showTime = el.showTime;
    obj.excludeHolidays = el.excludeHolidays;
    obj.excludeWeekends = el.excludeWeekends;
    obj.datesToShowType = el.datesToShowType;
    obj.minDate = el.minDate;
    obj.maxDate = el.maxDate;
    obj.minRelativeDate = el.minRelativeDate;
    obj.maxRelativeDate = el.maxRelativeDate;
    obj.excludeMinRelativeDate = el.excludeMinRelativeDate;
    obj.excludeMaxRelativeDate = el.excludeMaxRelativeDate;
    obj.viewMode = el.viewMode;
    break;

  case 'network':
    obj.required = !!el.required;
    obj.value = ctx.model[el.id];
    obj.rules = setValidationRules(el);
    obj.name = el.name || '';
    obj.id = el.id;
    obj.netMasks = el.listValues;
    break;

  case 'group':
  case 'inlineInputs': // тип группы с SM
    obj.tag = 'div';
    obj.globalState = globalState;
    obj.groupLinks = groupLinks;
    obj.value = ctx.model[el.id];
    obj.fields = el.childs;
    obj.name = el.name || '';
    // временное решение для SM тикетов, должно быть el.id
    // в sm в айдишниках содержатся англ. буквы
    obj.id = /^\d+$/.test(el.id) ? el.id : el.groupId;
    obj.visible = Boolean(el.visible);
    obj.hideBorder = Boolean(el.hideBorder);
    obj.hideName = Boolean(el.hideName);
    obj.addMore = Boolean(el.addMore);
    obj.maxNumberOfGroupCopies = el.maxNumberOfGroupCopies;
    obj.parentGroupId = parentGroupId;
    obj.mode = (() => {
      /**
       * TODO isInline - deprecated,
       * после исправления всех форм на controlsDisplayType удалить эту проверку
       */
      if (el.isInline) return 'horizontal';

      // TODO: удалить эту проверку, как только все формы перенесут с SM
      if (el.class === 'inlineInputs') return 'horizontal';

      if (
        el.controlsDisplayType
        && (['vertical', 'horizontal'].includes(el.controlsDisplayType))
      ) {
        return el.controlsDisplayType;
      }
      return 'vertical';
    })();
    break;

  case 'table':
    obj.tag = 'div';
    obj.value = ctx.model[el.id];
    obj.fields = el.columns;
    obj.name = el.name || '';
    obj.id = el.id;
    obj.hideName = el.hideName;
    break;

  case 'fiasSearch':
    obj.required = !!el.required;
    obj.value = ctx.model[el.id];
    obj.rules = setValidationRules(el);
    obj.name = el.name || '';
    obj.id = el.id;
    break;

  case 'employeeReplacement':
    obj.required = !!el.required;
    obj.value = ctx.model[el.id];
    obj.rules = setValidationRules(el);
    obj.name = el.name || '';
    obj.id = el.id;
    obj.addressChoiceTrouble = el.addressChoiceTrouble;
    obj.replaceDirection = el.replaceDirection;
    obj.roomChoiceTrouble = el.roomChoiceTrouble;
    obj.showEmployee = el.showEmployee;
    obj.showPlacementNumber = el.showPlacementNumber;
    obj.showPlacementType = el.showPlacementType;
    break;

  case 'resourceField':
    obj.required = !!el.required;
    obj.value = ctx.model[el.id];
    obj.rules = setValidationRules(el);
    obj.name = el.name || '';
    obj.id = el.id;
    obj.defaultValue = el.defaultValue;
    obj.showAddResource = el.showAddResource;
    obj.showAddRole = el.showAddRole;
    obj.resourceGroupName = el.resourceGroupName;
    obj.isShowAccessType = el.isShowAccessType;
    break;

  case 'database':
    obj.required = !!el.required;
    obj.rules = setValidationRules(el);
    obj.name = el.name;
    obj.databaseId = el.databaseId;
    obj.databaseColumns = el.databaseColumns ? [...el.databaseColumns] : [];
    break;

  case 'scale':
    obj.id = el.id;
    obj.value = ctx.model[el.id];
    obj.required = !!el.required;
    obj.rules = setValidationRules(el);
    obj.title = el.name;
    obj.description = el.description;
    obj.isInline = el.isInline;
    obj.viewMode = el.viewMode;
    obj.scaleValues = el?.listValues.map((i) => ({
      id: i.id,
      value: i?.extendedValues[0]?.value,
      text: i.name,
    })) || [];
    obj.startRange = el.startRange;
    obj.endRange = el.endRange;
    break;

  case 'calculatedField':
    obj.id = el.id;
    obj.name = el.name;
    obj.required = !!el.required;
    obj.dependedFieldIds = el.dependedFieldIds;
    obj.rules = setValidationRules(el);
    obj.value = ctx.model[el.id];
    break;

  case 'document':
    obj.id = el.id;
    obj.documentId = el.documentId;
    obj.documents = ctx.model[el.id] || [];
    obj.required = !!el.required;
    obj.rules = setValidationRules(el);
    obj.name = el.name;
    obj.addMoreDocument = Boolean(el.addMoreDocument);
    break;

  default:
    obj.required = !!el.required;
    obj.value = ctx.model[el.id];
    obj.rules = setValidationRules(el);
    obj.name = el.name || '';
    obj.id = el.id;
  }

  return obj;
};

export default setProps;
